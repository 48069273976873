import { useEffect, useState, useContext, useRef} from 'react'
import { Link, useLocation, useParams } from "react-router-dom";

import { MdDeleteOutline, MdEdit, MdOutlineGroup, MdFavoriteBorder } from "react-icons/md";

import './css/Tasks.css'
import { AppContext } from "../Context";
import Fetch, { Loader } from '../inc/Fetch'
import { Tasks } from "../components/Elements";
import { Input, MultiSelect, ImageUpload } from "../components/Form";

import Footer from "./Footer";
import Header from "./Header";
import SendTask from "./popups/SendTask";

let maxWidth = 900;



import { RiQuestionAnswerLine } from "react-icons/ri";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { GiShare } from "react-icons/gi";
import { IoIosAdd } from "react-icons/io";


export default function Cp() {

	let loc = useParams();

	const active = useRef(true);
	const {success, userinfo, showQuiz, showsendTask, showQuestion, languageText} = useContext(AppContext);
	const [open, setopen] = success;
	const [showQuizD, setshowQuizD] = showQuiz;
	const [showsendTask2, setshowsendTask] = showsendTask;
	const [showQ, setshowQ] = showQuestion;
	const [text, settext] = languageText;

	const [pageLoad, setpageLoad] = useState(true); 
	const [data, setdata] = useState([]); 
	const [filter, setfilter] = useState({}); 
	const [cats, setcats] = useState([]); 
	const [cls, setcls] = useState([]); 
	

	const levels = [
		{
			name:`- ${text?.levels} -`,
			val:false,
		},
		{
			name:text?.beginners,
			val:"Beginners",
		},
		{
			name:text?.intermediate,
			val:"Intermediate",
		},
		{
			name:text?.advanced,
			val:"Advanced",
		}
	];


	const [checked, setchecked] = useState([]); 
	
	function checkedQuestion(e, v) {

		let checked = e.target.checked;
		if (checked) {
			setchecked(p => [...p, v]);
		} else {
			setchecked(p => [...p?.filter(r => r?.ID != v?.ID)]);
		}
	}
	

	useEffect(() => {

		
		if (active.current != filter) {
			active.current = filter;

			setpageLoad(true);

			Fetch("Quiz/view", (d) => {

				if (!d) {
					setopen({
						title:text?.alertErrorTitle,
						short:text?.cant_get_data,
						error:true
					});
				} else {
					
					let quests = [];
					d?.tasks?.map((v) => {
						v?.questions?.map(r => {
							r.cats = v?.cats;
							r.classes = v?.classes;
							r.level = v?.level;
							quests.push(r);
						});
					});
					setdata(quests);

					let arrCats = [{ name: `- ${text?.categories} -`, val:false}];
					d?.cats?.map((v) => {
						arrCats.push({ name: v?.name, val:v?._id});
					});
					setcats(arrCats);

					let arrClas = [{ name: `- ${text?.classes} -`, val:false}];
					Object.keys(global.Classes)?.map((v) => {
						arrClas.push({ name: global.Classes[v], val:v});
					});
					setcls(arrClas);

					if (loc?.id) {
						
						Fetch("Question/view", (d) => {
							if (d?.ID) {
								setshowQ(d);
							}
							
						}, { _id:loc?.id });
					}
				}

				setpageLoad(false);
			}, { filter:filter});
		
		}
	}, [filter]);
	

	return (
		
		<>

			<Header size={maxWidth} title={text?.questions_new_task} />
			<div className='firstElem search'>
				<div className='mainWidth' style={{maxWidth:maxWidth}}>

					<div className='filterBoxTop'>

						<Input 
                            type="select"
                            title="" 
                            name="level"
                            validate={true}
                            values={levels}
                            onChange={(v) => setfilter(p => ({
                                ...p,
                                level:v,
                            }))}
                            value={filter?.level}
                        />

						<Input 
                            type="select"
                            title="" 
                            name="cat"
                            validate={true}
                            values={cats}
                            onChange={(v) => setfilter(p => ({
                                ...p,
                                cats:v,
                            }))}
                            value={filter?.cats}
							inputStyle={{width:200}}
                        />

						<Input 
                            type="select"
                            title="" 
                            name="classes"
                            validate={true}
                            values={cls}
                            onChange={(v) => setfilter(p => ({
                                ...p,
                                classes:v,
                            }))}
                            value={filter?.classes}
                        />
			


					</div>

				</div>
			</div>
			
			

			<section className='tasks'>

				{pageLoad ? <Loader /> :

				<div className='mainWidth' style={{maxWidth:maxWidth}}>
					
					<div className='questionBoxes'>
						<div className='quizzesList'>
							<table>
								<thead>
									<tr>
										<td style={{width:"5%"}}>#</td>
										<td style={{width:"5%"}}>{text?.select}</td>
										<td style={{width:"40%"}} className='align-left'>{text?.questions_title}</td>
										<td style={{width:"15%"}}>{text?.classes}</td>
										<td style={{width:"20%"}}>{text?.categories}</td>
										<td>{text?.type}</td>
										<td>{text?.levels}</td>
									</tr>
								</thead>
								
								<tbody>
									{data?.map((v, k) => {

										let check = false;
										let ck = checked?.filter(r => r?.ID == v?.ID);
										if (ck?.length) {
											check = true;
										}
										return (
											<tr key={k}>
												<td >{k + 1}</td>
												<td ><input className='checkboxFav' checked={check ? true : false} onChange={(e) => checkedQuestion(e, v)} style={{zoom:1.5}} type='checkbox' name='q' /></td>
												<td className='align-left'>
													<a onClick={() => setshowQ(v)} href='javascript:void(0)'>
														{v?.type == 1 && v?.question}
														{v?.type == 2 && <><b>{v?.video?.video_title}</b><br />{v?.video?.video_desc}</>}
														{v?.type == 3 && <><b>{v?.file?.file_title}</b><br />{v?.file?.file_desc}</> }
														{v?.type == 4 && <><b>{v?.file?.file_title}</b><br />{v?.file?.file_desc}</> }
														{v?.type == 5 && <><b>{v?.file?.file_title}</b><br />{v?.file?.file_desc}</> }
													</a>
												</td>
												<td>
												{v?.classes?.map((v, k) => {
													return (
														<span style={{display:"block"}}>{global?.Classes[v]}</span>
													);
												})}
												</td>
												<td>
													{v?.cats?.map((v, k) => {

														return (
															<span key={k} className='tag blue'>{v?.name}</span>
														);
													})}
												</td>
												<td>
													{v?.type == 1 && <span className='tag green'>{text?.type_text}</span>}
													{v?.type == 2 && <span className='tag green'>{text?.type_video}</span>}
													{v?.type == 3 && <span className='tag green'>{text?.type_audio}</span>}
													{v?.type == 4 && <span className='tag green'>{text?.type_audio}</span>}
													{v?.type == 5 && <span className='tag green'>{text?.type_img}</span>}
												</td>
												
												<td>
													{v?.level ? <span className='tag gray'>{v?.level}</span>: "--" }
												</td>
											</tr>
										);
									})}
									
									
								</tbody>
							</table>
							{!data?.length && <div style={{padding:30, textAlign:"center"}}>{text?.no_data_av}</div>}

							
						</div>
					</div>
					{/* <div className='sect5Box'>
						{data?.tasks?.length ? data?.tasks?.map((v, k) => {
							return (
								<Tasks key={k} data={v} />
							);
						})
						
						:
							<div></div>
						}

					</div> */}
					
					
				</div>}
			</section>

			{checked?.length ? <div className='addQuestButtonBox'>
				<button onClick={() => setshowsendTask(checked)} className='btn'>{text?.questions_to_send} ({checked?.length})</button>
			</div>: false}

			<Footer />
		</>
	)
}
