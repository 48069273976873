module.exports = {

    lets_start: "هيا نبدأ",
    cancel: "الغاء",
    back: "الرجوع",
    moreInfo: "المزيد من المعلومات..",
    
    home: "البيت",
    ourVision: "رؤيتنا",
    aboutUs: "من نحن",
    contactUS: "تواصلوا معنا",
    links: "روابط",
    social: "شبكات اجتماعية",

    aboutUsID:"66585da151c592080976fab1",
    ourVisionID:"665188235fd647e487463011",
    contactUsID:"66585dd051c592080976facb",
    
    alertErrorTitle:"اوبس!!",
    alertSuccessTitle:"واو!!",


    // Header
    you_are_loged_student:"انت مسجل كطالب",
    you_are_loged_teacher:"انت مسجل كمعلم",
    logged_out_success:"قمت بالخروج من الموقع بنجاح",
    favorite_questions:"الاسئلة المفضلة",
    manage_students:"ادارة الطلاب",
    logout:"الخروج",


    // login
    alertSuccesslogedIn:"تم تسجيل الدخول بنجاح",
    login_title:"الدخول",
    login_short:"مرحبا بكم في SpaceUp, قم بتسجيل الدخول في الموقع",
    username:"اسم المتخدم",
    pass:"كلمة المرور",
    loginButton:"سجل الآن",
    orLogin:"او التسجيل",
    ministry:"وزارة المعارف",


    // cp
    cp_title: "لوحة التحكم",
    deleteConfirm:"انت على وشك حذف مهمة!! هل انت متأكد؟",
    stats_task: "عدد المهام",
    stats_myStudents: "طلابي",
    stats_grade_avg: "معدل علامات الطلاب",
    stats_tasks_sent: "عدد المهام المرسلة", 
    stats_needs_attention: "بحاجة الى تدقيق", 

    profile: "معلوماتي", 
    level: "درجة العضوية", 
    fullname: "الاسم الكامل", 
    email: "البريد الالكتروني", 
    phone: "رقم الهاتف", 
    message: "الرسالة", 
    account_status: "حالة الحساب", 
    city: "المدينة", 
    school: "مدرسة", 
    classes: "الصفوف", 
    
    tasks: "مهام", 
    task_name: "اسم المهمة", 
    score: "العلامة", 
    waiting: "بالانتظار", 
    actions: "عمليات", 
    students: "طلاب", 


    // Profile
    profile_title: "حسابي الشخصي",
    info_updated: "تم تعديل معلوماتك بنجاح٫",
    update_your_profile: "عدل معلوماتك",

    address:"العنوان",
    yourID:"رقم الهوية",
    login_info:"معلومات الدخول",
    current_pass: "كلمة المرور الحالية",
    new_pass:"كلمة المرور الجديدة",
    confirm_pass:"تأكيد كلمة المرور",
    update_profile:"تعديل المعلومات",
    

    // fav
    fav_title:"المفضلة",
    fav_confirm_remove:"انت على وشك ازالة سؤال من المفضلة!! هل انت متأكد؟؟",
    questions:"اسئلة",

    questions_title:"عنوان السؤال",
    answers:"الاجابات",
    type:"النوع",
    remove:"ازالة",
    sent_to_students:"ارسل الى طلابي",


    // question types
    type_text:"نص",
    type_video:"فيديو",
    type_audio:"صوت",
    type_img:"صورة",


    // Questions
    questions_new_task: "مهمة جديدة",
    levels:"الصعوبة",
    beginners:"مبتدأ",
    intermediate:"متوسط",
    advanced:"متقدم",
    categories:"اقسام",

    cant_get_data:"لم نستطع اظهار المعلومات من الخادم",
    questions_to_send:"اسئلة للارسال",

    select:"اختيار",
    question:"سؤال",


    // MyTasks
    my_tasks:"مهامي",
    task_confirm_delete:"انت على وشك حذف مهمة!! هل انت متأكد؟",

    status:"الحالة",
    nothing_new:"لا جديد",
    cant_find_tasks:"لا يوجد اي مهمة في هذا الفسم حتى الآن",

    
    // Tasks
    all_categories:"جميع الاقسام",
    all_classes:"جميع الصفوف",


    // TaskStart
    confirm_task_send: "انت على وشك ارسال المهمة للمعلم!! هل انت متأكد؟",
    quiz_sent_success: "هذه المهمة ارسلت بنجاح",
    return_to_my_tasks:"الرجوع الى مهامي",
    your_score_is:"علامتك هي",
    task_info:"معلومات المهمة",
    task_time:"وقت المهمة",

    starts:"تبدأ",
    ends:"تنتهي",
    unlimited:"غير محدود",
    timer:"التوقيت",
    results:"النتائج",
    comments:"التعليقات",
    send_quiz_now:"ارسل المهمة الآن",
    next:"التالي",


    // StudentTasks
    number_of_waiting_tasks:"عدد المهام بالانتظار",
    my_grades_average:"معدل علاماتي",
    finished_avg:"معدل المهام المرسلة",
    not_started:"لم تبدأ بعد",


    // Dictionary
    not_logged_in:"انت غير مسجل",
    dic_search_holder:"ابحث عن كلمة",
    dictionary:"القاموس",


    // MyTasksInfo
    about_update_status:"انت على وشك تعديل الحالة!! هل انت متأكد؟",
    student_start_yet:"هذا الطالب لم يدخل الى هنا حتى الآن",
    confirm_delete_answers:"انت على وشك حذف اجابات الطالب! هل انت متأكد؟",
    finished:"منتهي",
    needs_attention:"بحاجة الى التدقيق",
    didnt_start:"بم يبدأ بعد",
    minutes:"دقائق",
    automatically:"تلقاي",
    teacher_decision:"تدقيق المعلم",
    added:"تمت الاضافة",
    start_at:"يبدأ",
    ends_at:"ينتهي",


    // Article
    msg_sent:"الرسالة ارسلت بمجاح.",
    or_contact_form:"او يمكنك تعبئة الحقول في الاسفل, وسنعاود الاتصال بكم قريبا.",
    send:"ارسل الآن",

    // Questions
    no_data_av: "لا يوجد معلومات في هذا القسم."

};