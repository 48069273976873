import { useEffect, useState, useContext, useRef, memo} from 'react'
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import TextTruncate from 'react-text-truncate';

import './css/footer.css'
import { AppContext } from "../Context";

import { FaFacebookF, FaYoutube, FaInstagramSquare } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import Fetch, { Loader, dateToText } from "../inc/Fetch";


export default memo(function Home() {

    const active = useRef(true);

    const [data, setdata] = useState({});
    const [load, setload] = useState(true);

    const {success, userinfo, languageText, language} = useContext(AppContext);
	const [open, setopen] = success;
	const [text, settext] = languageText;
    const [lang, setlang] = language;

    // useEffect(() => {
	// 	if (active.current)  {
    //         active.current = false;

    //         Fetch("Home", (d) => {

    //             if (!d?.error) {
    //                 let arr = {};
    //                 d?.articles?.map(v => {

    //                     if (lang == "AR") {
    //                         v.title = v?.ar_title
    //                         v.full = v?.ar_full
    //                         v.short = v?.ar_short
    //                     }

    //                     arr[v?._id] = v;
    //                 });

    //                 setdata(arr);
    //             }

    //             setload(false);
    //         });
            
    //     }
		
	// }, []);

    // console.log(data);

    // if (load) {
    //     return (<Loader />);
    // }
    return (
        <div className='footer'>

            <div className='mainWidth'>

                <div className='footerBox' style={{width:"30%"}}> 
                    <Link to={`/Article/${text?.aboutUsID}`}><b>{text?.aboutUs}</b></Link>

                    <text>At SpaceUp, we’ve been dedicated to transforming education for several years. Our mission is to empower students with innovative tools and resources, creating a dynamic and personalized learning experience..</text>

                </div>

                <div style={{flex:1}} className='footerBox contact'>
                    <Link to={`/Article/${text?.contactUsID}`}><b>{text?.contactUS}</b></Link>
                    
                    <text>
                    Kabul city, P.O. box 1399, 2496300<br />

                    Cellphone: 053-454-4442<br />

                    Cellphone: 050-672-9907<br />

                    Phone: 04-8507676
                    </text>

                </div>

                <div style={{flex:0.6}} className='footerBox links'>
                    <b>{text?.links}</b>
                    <ul>
                        <li><Link to='/'>{text?.home}</Link></li>
                        <li><Link to={`/Article/${text?.aboutUsID}`}>{text?.aboutUs}</Link></li>
                        <li><Link to={`/Article/${text?.ourVisionID}`}>{text?.ourVision}</Link></li>
                        <li><Link to={`/Article/${text?.contactUsID}`}>{text?.contactUS}</Link></li>
                    </ul>
                </div>

                <div style={{flex:1}} className='footerBox social'>
                    <b>{text?.social}</b>
                    <nav>
                        <Link><FaFacebookF /></Link>
                        <Link><BsTwitterX /></Link>
                        <Link><FaYoutube /></Link>
                        <Link><FaInstagramSquare /></Link>
                    </nav>
                </div>

            </div>

            <div className='footerText'>
                Copyright © 2024 <Link>SpaceUp</Link> Powered by <Link>Some company</Link>
            </div>
        </div>
    )
})
