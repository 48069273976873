import { useEffect, useState, useContext, useRef} from 'react'
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import Fetch, { Loader } from '../inc/Fetch'
import { AppContext } from "../Context";

import Dictionary from "../pages/popups/Dictionary";


import { MdOutlineLockOpen, MdOutlineFavoriteBorder, MdOutlineTranslate } from "react-icons/md";
import { RiProfileLine, RiListCheck2, RiLockFill } from "react-icons/ri";
import { PiStudentBold } from "react-icons/pi";
import { FiActivity, FiSend } from "react-icons/fi";
import { GoGear, GoGitPullRequest } from "react-icons/go";
import { IoHome, IoChatbubbleOutline } from "react-icons/io5";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { FaRegEye, FaChalkboardTeacher } from "react-icons/fa";


export function MainMenu() {

    let params = useParams();
    let loc = useLocation();

    
    
    const {success, userinfo, language, languageText, loginForm} = useContext(AppContext);
    const [logedIn, setlogedIn] = userinfo;
    const [lang, setlang] = language;
    const [text, settext] = languageText;
    const [openLogin, setopenLogin] = loginForm;

    return (
        <>
            <Link to='/' className={loc?.pathname == "/" ? "checked" : 'none'}><IoHome /> {text?.home}</Link>
            <Link style={{fontWeight:600}} to="/Games" className={loc?.pathname == "/Games" ? "checked" : 'none'}><IoMdInformationCircleOutline /> {text?.games}</Link>
            {logedIn?.userinfo?.userlevel >= 3 && <Link onClick={() => !logedIn?.userinfo?._id && setopenLogin(true) } to={logedIn?.userinfo?._id && '/Tasks'} style={{fontWeight:600}} className={loc?.pathname == "/Games" ? "checked" : 'none'}><IoMdInformationCircleOutline /> Tasks</Link>}
            <Link to="/Article/665183c05bc0b217f93dedae" className={loc?.pathname == "/Article/665183c05bc0b217f93dedae" ? "checked" : 'none'}><IoMdInformationCircleOutline /> {text?.aboutUs}</Link>
            <Link to="/Article/665188235fd647e487463011" className={loc?.pathname == "/Article/665188235fd647e487463011" ? "checked" : 'none'}><FaRegEye /> {text?.ourVision}</Link>
            <Link to="/Article/66518ce75fd647e4874630e1" className={loc?.pathname == "/Article/66518ce75fd647e4874630e1" ? "checked" : 'none'}><IoChatbubbleOutline /> {text?.contactUS}</Link>
        </>
    );
}

export function MainUserMenu({setopenDic}) {

    const loc = useLocation();
    const navigate = useNavigate();

    const {success, userinfo, languageText, showsendTask, cartData} = useContext(AppContext);
    const [open, setopen] = success;
    const [logedIn, setlogedIn] = userinfo;
    const [text, settext] = languageText;
    const [showsendTasks, setshowsendTasks] = showsendTask;
    const [cart, setcart] = cartData; 

    function logOut() {
        setlogedIn({});
        Fetch("logout", (d) => {
            setopen({
                title:text?.alertSuccessTitle,
                short:`${text?.logged_out_success}.`,
                // redirect:"/"
            });

            navigate("/");
        });
    }


    return (
        <>

            <ul className='userMenuBox'>
                {logedIn?.userinfo?.userlevel >= 3 ? 
                <>
                    <Link to='/Cp'><GoGear />{text?.cp_title}</Link>
                    <Link to='/Profile'><RiProfileLine />{text?.profile_title}</Link>
                    <Link onClick={() => setshowsendTasks({questions: cart})}><MdOutlineFavoriteBorder />{text?.favorite_questions} ({cart?.length})</Link>
                    {/* <Link to='/Questions'><FiSend />{text?.questions_new_task}</Link> */}
                    <Link to='/Tasks'><FiSend />New Task</Link>
                    <Link to='/MyTasks'><RiListCheck2 />{text?.my_tasks} {logedIn?.tasks_num ? `(${logedIn?.tasks_num})` : false}</Link>
                    <Link to='/Students'><PiStudentBold />{text?.manage_students}</Link>
                    <Link onClick={() => setopenDic(p => !p)}><MdOutlineTranslate />{text?.dictionary}</Link>
                    <Link onClick={logOut}><RiLockFill />{text?.logout}</Link>
                </>
                :
                <>
                    <Link to='/Cp'><GoGear />{text?.cp_title}</Link>
                    <Link to='/Profile'><RiProfileLine />{text?.profile_title}</Link>
                    {/* <Link to='/Training'><FaChalkboardTeacher />Training</Link> */}
                    <Link to='/MyTasks'><RiListCheck2 />{text?.my_tasks} {logedIn?.tasks_num ? `(${logedIn?.tasks_num})` : false}</Link>
                    <Link onClick={() => setopenDic(p => !p)}><MdOutlineTranslate />{text?.dictionary}</Link>
                    <Link onClick={logOut}><RiLockFill />{text?.logout}</Link>
                </>
                }
                
            </ul>
        </>
    );
}