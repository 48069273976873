import { useEffect, useState, useContext, useRef} from 'react'
import { Link, useLocation, useParams } from "react-router-dom";


import './css/Cp.css'
import { AppContext } from "../Context";
import Fetch, { Loader, dateToText } from "../inc/Fetch";

import Footer from "./Footer";
import Header from "./Header";
import SendTask from "./popups/SendTask";

import { RiQuestionAnswerLine } from "react-icons/ri";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { MdDeleteOutline, MdEdit, MdOutlineGroup, MdFavoriteBorder } from "react-icons/md";


export default function MyTasks() {

	const {success, userinfo, languageText} = useContext(AppContext);
	const [open, setopen] = success;
	const [logedIn, setlogedIn] = userinfo;
	const [text, settext] = languageText;

	const [loader, setloader] = useState(true);
	const [data, setdata] = useState([]);
	const [showsendTask, setshowsendTask] = useState(false); 
	
	const [checked, setchecked] = useState([]); 
	

	let loc = useParams();


	function checkedQuestion(e, v) {

		let checked = e.target.checked;
		if (checked) {
			setchecked(p => [...p, v]);
		} else {
			setchecked(p => [...p?.filter(r => r?._id != v?._id)]);
		}
	}


	function remove(v) { 

		let conf = window.confirm(text?.fav_confirm_remove);
		if (!conf) {
			return false;
		}

		Fetch("questions/fav/remove", (d) => {

			if (d?.error) {
				setopen({
                    title:text?.alertErrorTitle,
                    short:d?.error,
                    error:true
                });
			} else {
				setdata(p => {
					let arr = [];
					p?.map((r) =>{
						if (r?._id != v) {
							arr.push(r);
						}
						
					});
					
					return arr
				});
			}

			

			setloader(false);
		}, { _id: v });

	}


	useEffect(() => {

		if (!showsendTask) {
			
			Fetch("questions/fav/view", (d) => {

				if (!d?.error) {
					setdata(d);
				}
	
				setloader(false);
			});
			
			
		}
		

	}, [showsendTask]);

	
	if (loader) {
		return (
			<Loader />
		);
	}

	return (
		
		<>
			{showsendTask && <SendTask setopen={setshowsendTask} open={showsendTask} />}

			<Header title={text?.fav_title} />



			<div className='body' style={{marginTop:-90}}>
				<div className='mainWidth '>

					<div className='cp'>



						<div className='containerBox' style={{flex:1, }}>
					
							<hr className='boxLine'/>
							<h3 className='boxTitle' style={{fontSize:20, color:"#555"}}>{text?.questions}</h3>

							<div className='quizzesList'>
								<table>
									<thead>
										<tr>
											<td style={{width:"5%"}}>#</td>
											<td style={{width:"60%"}} className='align-left'>{text?.questions_title}</td>
											<td>{text?.answers}</td>
											<td>{text?.type}</td>
											<td style={{width:"15%"}}>{text?.remove}</td>
										</tr>
									</thead>
									
									<tbody>
										{data?.map((v, k) => {


											return (
												<tr key={k}>
													<td><label><input onChange={(e) => checkedQuestion(e, v?.question)} style={{zoom:1.5}} type='checkbox' className='checkboxFav' name='q' /></label></td>
													<td className='align-left'>
														{v?.question?.type == 1 && v?.question?.question}
														{v?.question?.type == 2 && <a target='_blank' href={v?.question?.video?.video_url}><b>{v?.question?.video?.video_title}</b><br />{v?.question?.video?.video_desc}</a>}
														{v?.question?.type == 3 && <><b>{v?.question?.file?.file_title}</b><br />{v?.question?.file?.file_desc}</> }
														{v?.question?.type == 4 && <><b>{v?.question?.file?.file_title}</b><br />{v?.question?.file?.file_desc}</> }
														{v?.question?.type == 5 && <><b>{v?.question?.file?.file_title}</b><br />{v?.question?.file?.file_desc}</> }
														
													</td>
													<td>{v?.question?.answers?.length}</td>
													<td>
														{v?.question?.type == 1 && <span className='tag green'>{text?.type_text}</span>}
														{v?.question?.type == 2 && <span className='tag green'>{text?.type_video}</span>}
														{v?.question?.type == 3 && <span className='tag green'>{text?.type_audio}</span>}
														{v?.question?.type == 4 && <span className='tag green'>{text?.type_audio}</span>}
														{v?.question?.type == 5 && <span className='tag green'>{text?.type_img}</span>}
													</td>
													
													<td>
														<a href='javascript:void(0)' onClick={() => remove(v?._id)} className='tableOptions red'><MdFavoriteBorder /></a>
													</td>
												</tr>
											);
										})}
										
									</tbody>
								</table>


								<button onClick={() => setshowsendTask(checked)} style={{padding:"6px 15px", margin:"20px 0"}} className='btn'>{text?.sent_to_students}</button>
							</div>
							
						</div>
					</div>


						
					
					
				</div>


			</div>

			<Footer />
		</>
	)
	
}
