
// console.log(process.env.NODE_ENV, window.location.protocol);
const hostName = `${process.env.NODE_ENV == "development" ? `http://${window.location.hostname}:2087/` : "https://www.spaceup.co.il/API/"}`;
// const hostName = `https://www.spaceup.co.il/API/`;
let Config = {
    
    key: "jJIyxe1P9fVfq3XgzZs12QIKIP1DMYzYo",
    domain: hostName,
    appName: `"Rmobile"`,
    imgUrl: hostName,
    uploadFolder:"Files/",

    pay_api_key: "9c12a91a195395bc7e7335b3d9938f87acc46112",
    pay_api_pass: "rmobile",
    pay_api_masof: "5601711420", 

    formatAllowed:"image/png,image/jpeg,image/gif,audio/mpeg"

}


export { Config }