import { useEffect, useState, useContext, useRef} from 'react'
import { Link, useParams } from "react-router-dom";
import parse from 'html-react-parser';
import {Helmet} from "react-helmet";

import './css/Article.css'
import { AppContext } from "../Context";
import Fetch, { Loader, dateToText } from "../inc/Fetch";
import SendTask from "./popups/SendTask";
import { Img } from "../components/Elements";
import { Input, MultiSelect, ImageUpload } from "../components/Form";

import Footer from "./Footer";
import Header from "./Header";
import ErrorPage from "./ErrorPage";

import { RiQuestionAnswerLine } from "react-icons/ri";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { MdDeleteOutline, MdEdit, MdOutlineGroup } from "react-icons/md";

export default function Cp() {

	const {success, userinfo, languageText, language} = useContext(AppContext);
	const [open, setopen] = success;
	const [logedIn, setlogedIn] = userinfo;
	const [text, settext] = languageText;
	const [lang, setlang] = language;


	const [loader, setloader] = useState(true);
	const [loader2, setloader2] = useState(false);
	const [data, setdata] = useState({});
	const [values, setvalues] = useState({});

	let params = useParams();

	function submit(e) {
        e.preventDefault();
        setloader2(true);

        Fetch("system/contact", (d) => {

            if (d?.error) {
                setopen({
                    title:text?.alertErrorTitle,
                    short:d?.error,
                    error:true
                });
            } else {  
                
                setopen({
                    title:text?.alertSuccessTitle,
                    short:text?.msg_sent,
                    error:false
                });


				setTimeout(() => {
					window.location.reload();
				}, 2500);
				
                
            }

            setloader2(false);
        }, values);

    }

	useEffect(() => {

		setloader2(true);
		// setloader(true);
		Fetch("Article", (d) => {

			
			if (!d?.error) {
				let arr = d[0];
				if (lang == "AR") {
					arr.title = arr?.ar_title;
					arr.short = arr?.ar_short;
					arr.full = arr?.ar_full;
				}
				setdata(arr);
			}

			setloader(false);
			setloader2(false);
		}, { _id: params?.id  });
		
	}, [params?.id]);


	if (loader) {
		return (<Loader />)
	}

	if (!data?.title) {
		return (
			<ErrorPage type="empty"/>
		);
	}


	return (
		
		<>
			<Helmet>

				<title>{data?.title}</title>
				<meta name="description" content="It is where your English journey begins" />
				<meta name="keyword" content="Spaceup, تعليم الانجليزية, english learn, learning, تعلم من العربي الى الانجليزي," />

				<meta property="og:title" content={data?.title} />
				<meta property="og:description" content="It is where your English journey begins" />
				<meta property="og:image" content="https://www.spaceup.co.il/static/media/banner.186a0fb0ec521e59cf18.jpg" />

			</Helmet>

			<Header title=""/>
			
			{loader2 && <Loader />}
			<div className='articleBox'>
				<h2>{data?.title}</h2>


				<div className='fullBox' >
					{parse(data?.full)}
				</div>

				{params?.id == text?.contactUsID &&
					<form onSubmit={submit}>
						<p>{text?.or_contact_form}</p>

						<Input 
							title={text?.fullname} 
							name="name"
							type="text"
							validate={true}
							onChange={(v) => setvalues(p => ({
								...p,
								name:v,
							}))}
							value={values?.name}
						/>

						<Input 
							title={text?.email} 
							name="email"
							type="email"
							validate={true}
							onChange={(v) => setvalues(p => ({
								...p,
								email:v,
							}))}
							value={values?.email}
						/>

						<Input 
							title={text?.phone}
							name="phone"
							type="number"
							// validate={true}
							onChange={(v) => setvalues(p => ({
								...p,
								phone:v,
							}))}
							value={values?.phone}
						/>

						<Input 
							title={text?.message}
							name="full"
							type="textarea"
							validate={true}
							onChange={(v) => setvalues(p => ({
								...p,
								full:v,
							}))}
							value={values?.full}
						/>

						<button className='btn'>{text?.send}</button>

					</form>
				}
				
			</div>

			<Footer />
		</>
	)
}
