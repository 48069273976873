import { memo, useEffect, useState, useContext, useRef } from 'react';


import './css/Login.css'
import manSrc from '../../images/moe.png'

import { AppContext } from "../../Context";
import Popups from "../../components/Popups";
import { Input } from "../../components/Form";

import Fetch, { Loader, dateToText } from "../../inc/Fetch";


export default memo(function Login({setopen, open}) {

    const active = useRef(true);
    const {success, userinfo, languageText} = useContext(AppContext);
    const [suc, setsuc] = success;
    const [logedIn, setlogedIn] = userinfo;
	const [text, settext] = languageText;

	const [access, setaccess] = useState(false);

    const [values, setvalues] = useState({});
    const [loader, setloader] = useState(false);


    function submit(e) {
        e.preventDefault();
        setloader(true);

        Fetch("web/login", (d) => {

            if (d?.error) {
                if (d?.error?.captcha) {
                    console.log("captcha");
                } else {
                    setsuc({
                        title:text?.alertErrorTitle,
                        short:d?.error,
                        error:true
                    });
                }
                
            } else {  
                
                setsuc({
                    title:text?.alertSuccessTitle,
                    short:text?.alertSuccesslogedIn,
                    error:false
                });
                setTimeout(() => {
                    setopen(false);
                    setlogedIn(d);
                }, 2500);
                
            }

            setloader(false);
        }, values);

    }


    return (

        <Popups setOpen={setopen} style={{maxWidth:400, borderTop:"4px solid #fd745a"}}>
            {loader && <Loader />}
           <>

                <div className='popupBody interface data' >

                    <form onSubmit={submit} className='loginForm'>
                        <h2>{text?.login_title}</h2>
                        <p>{text?.login_short}</p>

                            <Input 
                                title={text?.username} 
                                name="user"
                                type="text"
                                autoFocus={true}
                                // validate={true}
                                onChange={(v) => setvalues(p => ({
                                    ...p,
                                    user:v,
                                }))}
                            />

                            <Input 
                                title={text?.pass}
                                name="pass"
                                type="password"
                                // validate={true}
                                onChange={(v) => setvalues(p => ({
                                    ...p,
                                    pass:v,
                                }))}
                            />

                            {/* <object>
                                <embed src="/static/filename.svg" />
                            </object> */}

                            <button role="button" aria-haspopup="true" className='btn'>{text?.loginButton}</button>

                            <div className='or'>
                                <span>{text?.orLogin}</span>
                            </div>

                            <a className='btn extLogin' href=''><img src={manSrc} />{text?.ministry} </a>
                        
                    </form>

                    
                </div>
            </> 

            

        </Popups>
        
    );
})