import { memo, useEffect, useState, useContext, useRef } from 'react';
import './css/questions.css'

import { AppContext } from "../../Context";
import Popups from "../../components/Popups";
import { Input, MultiSelect, ImageUpload } from "../../components/Form";
import { Config } from '../../inc/Config'
import Fetch, { Loader, dateToText, PlaySound, UploadFile } from "../../inc/Fetch";


import { RxDragHandleDots2 } from "react-icons/rx";
import { FaRegCircle, FaRegCheckCircle } from "react-icons/fa";
import { GoCheckCircleFill, GoCircle } from "react-icons/go";
import { LuPlus } from "react-icons/lu";
import { MdDelete } from "react-icons/md";
import { IoCheckmarkDoneSharp, IoSearch, IoClose } from "react-icons/io5";
import { FaCirclePlay } from "react-icons/fa6";


export default memo(function QuestionAdd({setOpen, Open, setquestions}) {

    const {success, userinfo, options} = useContext(AppContext);
    const [open, setopen] = success;

    const [loader, setloader] = useState(false);
    const [values, setvalues] = useState({});

    const [answersNum, setanswersNum] = useState(Open?.answers?.length ? Open?.answers?.length : 4);
    const [type, settype] = useState(Open?.type ? Open?.type : 0);
    const [question, setquestion] = useState(Open?.question ? Open?.question : "");
    const [answers, setanswers] = useState({});
    const [checked, setchecked] = useState("none");
    const [answersType, setanswersType] = useState(0);

    const [video, setvideo] = useState(Open?.video?.video_url ? Open?.video : {});
    const [file, setfile] = useState(Open?.file?.file || Open?.file?.file_word ? Open?.file : {});


    const types = [
        {
            name:"- choose -",
            val:0
        },
        {
            name:"Text question",
            val:1
        },
        {
            name:"Video",
            val:2
        },
        {
            name:"Audio file",
            val:3
        },
        {
            name:"Text to voice",
            val:5
        },
        {
            name:"Image",
            val:4
        }
    ];


    async function uploadFile(event) {
        
        let UploadF = await UploadFile(event);
        
        Fetch("upload", (d) => { 
            
            if (d[0]) {
                setfile(p => ({
                    ...p,
                    file:d[0],
                }))
            }

            setloader(false);
        }, {files: UploadF});
    }

    function submit(e) {

        e.preventDefault();

        if (checked == "none" && answersType == 0) {
            setopen({
                title:"Ops!!",
                short:"Please choose the right answer.",
                error:true
            });

            return false;
        }

        let arr = { }
        
        if (type == 1) {
            arr = { ID:Date.now(), question: question, type:type, answers:[]};
        } else if (type == 2) {
            arr = { ID:Date.now(), video: video, type:type, answers:[]};
        } else {
            arr = { ID:Date.now(), file: file, type:type, answers:[]};
        }

        
        Object.entries(answers)?.map((v, k) => {

            let ck = checked == k ? true : false;
            if (v[1]) {
                arr.answers.push({ answer:v[1], true:ck });
            }
            
        });

        if (Open?.ID) {

            setquestions(p => {
                let array = [];
                p?.map((v) => {

                    if (v?.ID == Open?.ID) {

                        v.question = question;
                        v.video = video;
                        v.file = file;
                        v.answers = arr?.answers;
                        v.type = arr?.type;

                        if (answersType != 0) {
                            v.answersType = answersType;
                        } else {
                            delete v.answersType;
                        }
                    }


                    
                    array.push(v);
                    
                });


                return array
            });
            
            setOpen(false);
        } else {

            if (answersType != 0) {
                arr.answersType = answersType;
            } else {
                delete arr.answersType;
            }


            setquestions(p => [...p, arr]);
            setOpen(false);
        }

        
    }

    function answersRes(v, index) { 
        
        if (v?.type == "change") {

            setanswers(p => ({
                ...p,
                [index]:v?.target?.value,
            }));
            
        } else {
            setchecked(index);
        }
    }

    

    function deleteRes(index) {

        setanswers(p => {

            let arr = {};
            let ii = 0;
            Object.entries(p)?.map((v, k) => {

                if (k != index) {
                    
                    arr[ii] = v[1];

                    ii++;
                }
            });
            return  arr
        });

        setanswersNum(p => p <= 2 ? 2 : p - 1);

        setchecked(p => p == index && 'none');
    }



    useEffect(() => {
        if (Open?.answers) {

            let arr = {}
            let ck = 'none';
            Open?.answers?.map((v, k) => {
                arr[k] = v?.answer;
                if (v?.true) {
                    ck = k;
                }
            });

            setanswers(arr);
            setchecked(ck);
            if (Open?.answersType) {
                setanswersType(Open?.answersType);
            }
            

        }
    }, [])
    

    return (

        <Popups setOpen={setOpen} style={{maxWidth:600, borderTop:"4px solid #fd745a", maxHeight:"96vh" }} index={10}>
            {loader && <Loader />}
          
           <>
                
                
                <div className='popupBody interface data newQuestion' >
                        
                    <form id='sec3' className='inData checked' onSubmit={submit} style={{padding:0}}> 
                        
                        <div style={{padding:20, background:"#f9f9f9", borderBottom:"1px solid #e8e8e8"}}>
                            <h2 style={{justifyContent:"space-between", padding:"0px 10px", fontWeight:600}}>New Question</h2>
                            <Input 
                                title="Question type" 
                                name="type"
                                type="select"
                                validate={true}
                                onChange={(v) => settype(v)}
                                value={type}
                                values={types}
                                style={{maxWidth:"200px"}}
                                inputStyle={{maxWidth:"200px"}}
                            />
                        </div>
                        
                        {type == 1 && 
                            <div style={{padding:"10px 20px"}}>
                                <Input 
                                    title="The question" 
                                    name="question"
                                    type="textarea"
                                    validate={true}
                                    onChange={(v) => setquestion(v)}
                                    value={question}
                                    style={{maxWidth:"100%"}}
                                    inputStyle={{maxWidth:"100%", minHeight:50, direction:"ltr",}}
                                />

                            </div>
                        }


                        {type == 2 && 
                            <div style={{padding:"10px 20px"}}>
                                <Input 
                                    title="Youtube url" 
                                    name="video_url"
                                    type="text"
                                    placeholder={"https://www.youtube.com/watch?v=52emKgA5rfE"}
                                    validate={true}
                                    onChange={(v) => setvideo(p => ({
                                        ...p,
                                        video_url:v,
                                    }))}
                                    value={video?.video_url}
                                    style={{maxWidth:"100%"}}
                                    inputStyle={{maxWidth:"100%", direction:"ltr",}}
                                />

                                <Input 
                                    title="Video title" 
                                    name="video_title"
                                    type="text"
                                    validate={true}
                                    onChange={(v) => setvideo(p => ({
                                        ...p,
                                        video_title:v,
                                    }))}
                                    value={video?.video_title}
                                    style={{maxWidth:"100%"}}
                                    inputStyle={{maxWidth:"100%",  direction:"ltr",}}
                                />

                                <Input 
                                    title="Video description" 
                                    name="video_desc"
                                    type="textarea"
                                    onChange={(v) => setvideo(p => ({
                                        ...p,
                                        video_desc:v,
                                    }))}
                                    value={video?.video_desc}
                                    style={{maxWidth:"100%"}}
                                    inputStyle={{maxWidth:"100%", minHeight:60, direction:"ltr",}}
                                />

                                

                            </div>
                        }
                        {type == 3 && 
                            <div style={{padding:"10px 20px"}}>

                                <label className="formInputBox focused" style={{maxWidth:300 }}>
                                    <span>Audio file: <b>*</b></span>
                                    <input onChange={(e) => uploadFile(e)} autocomplete="new-password" type="file" name="audio" value="" />

                                    {file?.file && <audio controls>
                                        <source src={`${Config?.imgUrl}${file?.file}`} type="audio/mpeg" />
                                    </audio>}

                                </label>
                                
                                

                                <Input 
                                    title="Audio title" 
                                    name="file_title"
                                    type="text"
                                    validate={true}
                                    onChange={(v) => setfile(p => ({
                                        ...p,
                                        file_title:v,
                                    }))}
                                    value={file?.file_title}
                                    style={{maxWidth:"100%"}}
                                    inputStyle={{maxWidth:"100%",  direction:"ltr",}}
                                />

                                <Input 
                                    title="Audio description" 
                                    name="file_desc"
                                    type="textarea"
                                    onChange={(v) => setfile(p => ({
                                        ...p,
                                        file_desc:v,
                                    }))}
                                    value={file?.file_desc}
                                    style={{maxWidth:"100%"}}
                                    inputStyle={{maxWidth:"100%", minHeight:60, direction:"ltr",}}
                                />

    

                            </div>
                        }

                        {type == 4 && 
                            <div style={{padding:"10px 20px"}}>

                                <ImageUpload 
                                    title="Upload Image" 
                                    name="file_title"
                                    onChange={(v) => setfile(p => ({
                                        ...p,
                                        file:v,
                                    }))}
                                    value={file?.file}
                                />
                                {/* <label className="formInputBox focused" style={{maxWidth:300 }}>
                                    <span>בחר תמונה: <b>*</b></span>
                                    <input onChange={(e) => uploadFile(e)} autocomplete="new-password" type="file" name="audio" value="" />

                                    {file?.file && 
                                        <img style={{width:100, height:100, }} src={`${Config?.imgUrl}${file?.file}`} />
                                    }

                                </label> */}

                                

                                <Input 
                                    title="Image title" 
                                    name="file_title"
                                    type="text"
                                    validate={true}
                                    onChange={(v) => setfile(p => ({
                                        ...p,
                                        file_title:v,
                                    }))}
                                    value={file?.file_title}
                                    style={{maxWidth:"100%"}}
                                    inputStyle={{maxWidth:"100%",  direction:"ltr",}}
                                />

                                <Input 
                                    title="Image description" 
                                    name="file_desc"
                                    type="textarea"
                                    onChange={(v) => setfile(p => ({
                                        ...p,
                                        file_desc:v,
                                    }))}
                                    value={file?.file_desc}
                                    style={{maxWidth:"100%"}}
                                    inputStyle={{maxWidth:"100%", minHeight:60, direction:"ltr",}}
                                />

                            </div>
                        }

                        {type == 5 && 
                            <div style={{padding:"10px 20px"}}>

                                <div style={{display:"flex", alignItems:"center"}}>
                                    <Input 
                                        title="Words in English" 
                                        name="file_title"
                                        type="text"
                                        validate={true}
                                        onChange={(v) => setfile(p => ({
                                            ...p,
                                            file_word:v,
                                        }))}
                                        value={file?.file_word}
                                        style={{maxWidth:400}}
                                        inputStyle={{maxWidth:"100%",  direction:"ltr",}}
                                    />
                                    <a style={{fontSize:24}} onClick={() => PlaySound(file?.file_word)}><FaCirclePlay /></a>
                                </div>
        

                                <Input 
                                    title="Title " 
                                    name="file_title"
                                    type="text"
                                    validate={true}
                                    onChange={(v) => setfile(p => ({
                                        ...p,
                                        file_title:v,
                                    }))}
                                    value={file?.file_title}
                                    style={{maxWidth:"100%"}}
                                    inputStyle={{maxWidth:"100%",  direction:"ltr",}}
                                />

                                <Input 
                                    title="Description" 
                                    name="file_desc"
                                    type="textarea"
                                    onChange={(v) => setfile(p => ({
                                        ...p,
                                        file_desc:v,
                                    }))}
                                    value={file?.file_desc}
                                    style={{maxWidth:"100%"}}
                                    inputStyle={{maxWidth:"100%", minHeight:60, direction:"ltr",}}
                                />


    

                            </div>
                        }

                        
                        <>
                            <nav className='answersType'>
                                <a onClick={() => setanswersType(0)} className={answersType == 0 ? `checked` : 'none'}>Choices</a>
                                <a onClick={() => setanswersType(2)} className={answersType == 2 ? `checked` : 'none'}>Letters</a>
                            </nav>
                            
                            {answersType == 0 && <div className='answersBox'>
                                {Array.from(Array(answersNum), (e, i) => { 
                                    return (
                                        <label>
                                            <MdDelete onClick={() => deleteRes(i)} className='ico'/>
                                            <input type='text' name='answer' onChange={(v) => answersRes(v, i)} value={answers[i] || ""} placeholder={`Answer ${i + 1}`} />
                                            <a className={`check ${checked == i && "on"}`} onClick={() => answersRes(false, i)}>{checked == i ? <GoCheckCircleFill  /> : <GoCircle  />}</a>
                                        </label>
                                    );
                                })}
                                

                                <button type='button' style={{fontSize:13}} onClick={() => setanswersNum(p => p + 1)} className='btn'><LuPlus />Add new question</button>
                            </div>}

                            {answersType == 2 && 
                                <div className='answersBox' style={{padding:20}}>
                                    <label>
                                        <input type='text' name='answer' onChange={(v) => setanswers({'0':v?.target?.value})} value={answers[0] || ""}  placeholder='Word...'/> 
                                    </label>

                                </div>
                            }
                                    

                            {type ? <div className='buttonsDataPopUp'>

                                <button type="button" onClick={() => setOpen(false)} className='btn gray'><IoClose /> Cancel</button>
                                <button className='btn green'><IoCheckmarkDoneSharp /> Save Question</button>
                                
                            </div> : false}
                        </>

                    </form>


                    
                </div>
            </> 

            

        </Popups>
        
    );
})