import { useEffect, useState, useContext, useRef} from 'react'
import { Link, useLocation, useParams } from "react-router-dom";


import './css/Cp.css'
import { AppContext } from "../Context";
import Fetch, { Loader, dateToText } from "../inc/Fetch";
import { Img } from "../components/Elements";
import { Input, MultiSelect } from "../components/Form";

import Footer from "./Footer";
import Header from "./Header";
import Student from "./popups/Student"; 

import { RiQuestionAnswerLine } from "react-icons/ri";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { MdDeleteOutline, MdEdit, MdOutlineGroup } from "react-icons/md";


export default function MyTasks() {

	const {success, userinfo} = useContext(AppContext);
	const [open, setopen] = success;
	const [logedIn, setlogedIn] = userinfo;

	const [loader, setloader] = useState(true);
	const [data, setdata] = useState([]);
	const [showStudent, setshowStudent] = useState(false); 
	const [showStats, setshowStats] = useState(false); 

	const [classes, setclasses] = useState([]); 
	const [filter, setfilter] = useState({}); 

	let loc = useParams();

	function deleteStudent(v) { 

		let conf = window.confirm("You are about to delete a Task! are you sure?");
		if (!conf) {
			return false;
		}

		Fetch("students/delete", (d) => {

			if (d?.error) {

				setopen({
					title:"Ops!!",
					short:d?.error,
					error:true,
				});

			} else {
				setdata(p => {
					let arr = [];
					p?.students?.map((r) =>{
						if (r?._id != v) {
							arr.push(r);
						}
						
					});
					
					
					return { students:arr}
				});
			}
			

			setloader(false);
		}, { _id: v });

	}

	function loadData() {

		Fetch("students/view", (d) => {

			if (!d?.error) {
				setdata(d);
			}

			setloader(false);
		}, filter);
	}

	useEffect(() => {

		if (!showStudent) { 

			let arr = [{ name: "- All classes -", val: "" }];
			logedIn?.userinfo?.classes?.map((v) => {

				let cls = v.split("-")[0];
				let grd = v.split("-")[1];
				
				arr.push({ name:`${global.Classes[cls]} ${global.Grades[grd]}`, val: v })
				
			});

			setclasses(arr);


			loadData();
			
			
			
		}
		

	}, [showStudent, filter]);

	
	if (loader) {
		return (
			<Loader />
		);
	}

	return (
		
		<>
			{showStudent && <Student setopen={setshowStudent} open={showStudent} />}

			<Header title={"My Students"} />



			<div className='body' style={{marginTop:-70}}>
				<div className='mainWidth '>

					<div className='cp'>



						<div className='containerBox' style={{flex:1, }}>

							<div  style={{display:"flex", justifyContent:"space-between"}}>
								<div>	
									<hr className='boxLine'/>
									<h3 className='boxTitle' style={{fontSize:20, color:"#555"}}>Students List</h3>
								</div>

								<div className="titleWithFilter">
									<Input 
										title="" 
										name="Classes"
										type="select"
										
										onChange={(v) => setfilter(p => ({
											...p,
											classes:v,
										}))}
										style={{padding:0, maxWidth:200, }}
										values={classes}
										value={filter?.classes}
									/>
								</div>
								
							</div>

							<div className='quizzesList'>
								<table>
									<thead>
										<tr>
											<td>Image</td>
											<td style={{width:"30%"}} className='align-left'>Student name</td>
											<td >Last Login</td>
											<td >#ID</td>
											<td>Classes</td>
											<td>Status</td>
											<td style={{width:"15%"}}>Actions</td>
										</tr>
									</thead>
									
									<tbody>
										{data?.students?.map((v, k) => {
											return (
												<tr key={k}>
													<td><Link onClick={() => setshowStudent({ student:v?._id, tab:1 })}><Img style={{width:50, height:50, objectFit:"cover", borderRadius:"50%"}} src={'img/60x60' + v?.img} /></Link></td>
													<td className='align-left'><Link onClick={() => setshowStudent({ student:v?._id, tab:1 })}>{v?.name}</Link></td>
													<td>{dateToText(v?.last_login)}</td>
													<td>{v?.zehot ? v?.zehot : "--"}</td>
													<td>
														{v?.classes?.map((c, kk) => {

															let clsSplit = c?.split("-");
															let classNames = `${global.Classes[clsSplit[0]]} (${global.Grades[clsSplit[1]]})`
															return (
																<span key={kk} className='tag' style={{margin:"4px auto"}}>{classNames}</span>
															);
														})}
													</td>
													<td>{v?.status < 1 ? <span className='tag gray' style={{margin:"4px auto"}}>Blocked</span> : <span className='tag green' style={{margin:"4px auto"}}>Active</span>}</td>
													
													<td>
														
														<a href='javascript:void(0)' onClick={() => deleteStudent(v?._id)} className='tableOptions red'><MdDeleteOutline /></a>
														&nbsp;&nbsp;
														<a href='javascript:void(0)' onClick={() => setshowStudent({ student:v?._id })} className='tableOptions green'><MdEdit /></a>
														
													</td>
												</tr>
											);
										})}
										
									</tbody>
								</table>
							</div>
							
						</div>
					</div>


						
					
					
				</div>


			</div>

			<Footer />
		</>
	)
	
}
