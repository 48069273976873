import { useEffect, useState, useContext, useRef} from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";


import './css/Cp.css'
import { AppContext } from "../Context";
import Fetch, { Loader, dateToText } from "../inc/Fetch";
import SendTask from "./popups/SendTask";
import { Img } from "../components/Elements";

import Footer from "./Footer";
import Header from "./Header";

import { RiQuestionAnswerLine } from "react-icons/ri";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { MdDeleteOutline, MdEdit, MdOutlineGroup } from "react-icons/md";

export default function Cp() {

	let nav = useNavigate();

	const {success, userinfo, languageText} = useContext(AppContext);
	const [open, setopen] = success;
	const [logedIn, setlogedIn] = userinfo;
	const [text, settext] = languageText;

	const [loader, setloader] = useState(true);
	const [data, setdata] = useState([]);
	
	const [showsendTask, setshowsendTask] = useState(false); 



	useEffect(() => {

			
		Fetch("tasks/view", (d) => {

			if (!d?.error) {
				setdata(d);

			}

			setloader(false);
		});

	}, []);

	return (
		
		<>
			{showsendTask && <SendTask setopen={setshowsendTask} open={showsendTask} />}
			<Header  />
			<div className='firstElem stats'>

				<div className='mainWidth'>

					<section>
						<div>
							<b>{text?.stats_task}</b>
							<h3>{data?.stats?.tasks}</h3>
							{/* <span><FaArrowUp /> 1.3%</span> */}
						</div>
					</section>

					<section>
						<div>
							<b>{text?.my_grades_average}</b>
							<h3>{data?.stats?.avg}%</h3>
							{/* <span><FaArrowDown /> 2.3%</span> */}
						</div>
					</section>

					<section>
						<div>
							<b>{text?.stats_needs_attention}</b>
							<h3>{data?.stats?.notFinished}</h3>
							{/* <span><FaArrowUp /> 1.3%</span> */}
						</div>
					</section>


				</div>
			</div>


			<div className='body'>
				<div className='mainWidth '>

					<div className='cp'>

						<div className='containerBox' style={{width:300}}>
					
							<hr className='boxLine'/>
							<h3 className='boxTitle'>{text?.profile}</h3>

							<ul className='profile'>
								<div className='pImg'>
									<Img src={logedIn?.userinfo?.img} />
									<h5>{logedIn?.userinfo?.name}</h5>
								</div>
								<li><span>{text?.username}:</span><b>{logedIn?.userinfo?.user}</b></li>
								<li><span>{text?.level}:</span><b>{logedIn?.userinfo?.userlevel == 1 && "Student"}{logedIn?.userinfo?.userlevel == 3 && "Teacher"}{logedIn?.userinfo?.userlevel == 5 && "Manager"}</b></li>
								<li><span>{text?.fullname}:</span><b>{logedIn?.userinfo?.name}</b></li>
								<li><span>{text?.account_status}:</span><b>{logedIn?.userinfo?.status >= 1 ? "Active" : "Deactive"}</b></li>
								<li><span>{text?.city}:</span><b>{logedIn?.userinfo?.city ? logedIn?.userinfo?.city : "--"}</b></li>
								<li><span>{text?.school}:</span><b>{logedIn?.userinfo?.school_id[0]?.name}</b></li>
								<li><span>{text?.classes}:</span><b>{logedIn?.userinfo?.classes?.map((c, kk) => {
										let classNames = `${global.Classes[c[0]]} (${global.Grades[c[2]]})`
										return (
											<span key={kk} className='tag' style={{margin:"4px auto", color:"#fff"}}>{classNames}</span>
										);
									})}	</b></li>
							</ul>
						</div>


						<div className='containerBox' style={{flex:1, }}>
					
							<hr className='boxLine'/>
							<h3 className='boxTitle'>{text?.tasks}</h3>

							<div className='quizzesList'>
								<table>
								<thead>
										<tr>
											<td style={{width:"65%"}} className='align-left'>{text?.task_name}</td>
											<td>{text?.score}</td>
											<td>{text?.timer}</td>
											<td>{text?.status}</td>
										</tr>
									</thead>
									
									<tbody>
										{data?.tasks?.map((v, k) => {

											if (k > 10) {
												return false;
											}

											return (
												<tr style={{ backgroundColor: !v?.st_score && !v?.st_finished && '#ffeedc'}}  key={k}>
													<td className='align-left'><Link to={`/TaskStart/${v?._id}`}><b>{v?.name}</b></Link> {v?.full && <Link to={`/TaskStart/${v?._id}`}><br /><p>{v?.full}</p></Link>}</td>
													<td>{v?.st_score ? <span className='tag green'>{v?.st_score}%</span> : "--"}</td>
													
													<td>{v?.st_timer}</td>
													<td>
														{v?.st_score && v?.st_finished && <span className={`tag green`}>Finished</span>}
														{v?.st_score && !v?.st_finished && <span className='tag orange'>Waiting..</span>}
														{!v?.st_score && !v?.st_finished && <span className='tag gray'>Not started</span>}
													</td>
													
												</tr>
											);
										})}
										
									</tbody>
								</table>
							</div>
							
						</div>
					</div>


						
					
					
				</div>


			</div>

			<Footer />
		</>
	)
}
