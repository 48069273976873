import { memo, useEffect, useState, useContext, useRef } from 'react';

import './css/QuizSend.css'
import manSrc from '../../images/moe.png'

import { Config } from '../../inc/Config'
import { AppContext } from "../../Context";
import Popups from "../../components/Popups";

import Fetch, { Loader, dateToText } from "../../inc/Fetch";
import { Input, MultiSelect } from "../../components/Form";

import { MdDeleteOutline } from "react-icons/md";

import { Question } from "../../components/Elements";

export default memo(function SendTasks({setopen, open}) {

    const {languageText} = useContext(AppContext);
	const [text, settext] = languageText;

    return (

        <>
        
        <Popups setOpen={setopen} style={{maxWidth:600, borderTop:"4px solid #fd745a", maxHeight:"96vh"}}>
           <>

                <div className='popupBody interface data quizSend' >

                    <div className='dataHead' style={{height:60}}>
                        <nav >
                            <a className='checked'>{text?.question}</a>
                        
                        </nav>
                    </div>

                    <form >
                        
                    
                        <div className={`tabsSection active`}>

                            <div className='quizPrevBox' style={{height:"auto"}}>
                                <div className='questionsBox' style={{border:"0"}}>
                                    <Question v={open} />
                                </div>
                            </div>
                            
                        </div>

                    </form>
                    

                    
                </div>
            </> 

            

        </Popups>
        </>
    );
})