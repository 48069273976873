module.exports = {

    lets_start: "Let's start!!",
    cancel: "CANCEL",
    back: "BACK",
    moreInfo: "More info...",
    
    home: "Home",
    ourVision: "Our Vision",
    aboutUs: "About us",
    contactUS: "Contact us",
    links: "Links",
    social: "Social",

    aboutUsID:"66585da151c592080976fab1",
    ourVisionID:"665188235fd647e487463011",
    contactUsID:"66585dd051c592080976facb",
    
    alertErrorTitle:"Ops!!",
    alertSuccessTitle:"Wow!!",


    // Header
    you_are_loged_student:"You are logged in as Student",
    you_are_loged_teacher:"You are logged in as Teacher",
    logged_out_success:"You have logged out successfully",
    favorite_questions:"Favorite Questions",
    manage_students:"Manage Students",
    logout:"Logout",


    // login
    alertSuccesslogedIn:"You have successfuly loged in",
    login_title:"LOG IN",
    login_short:"Welcome to SpaceUp, please login to your account.",
    username:"USERNAME",
    pass:"PASSWORD",
    loginButton:"Login now",
    orLogin:"Or sign in",
    ministry:"Ministry of Education",


    // cp
    cp_title: "Control Panel",
    deleteConfirm:"You are about to delete a Task! are you sure?",
    stats_task: "Total number of tasks",
    stats_myStudents: "My Students",
    stats_grade_avg: "Students grades Average",
    stats_tasks_sent: "Tasks pending", 
    stats_needs_attention: "Tasks pending", 

    profile: "Profile", 
    level: "User level", 
    fullname: "Full name", 
    phone: "Phone", 
    email: "Email address", 
    message: "Message", 
    account_status: "Account Status", 
    city: "City", 
    school: "School", 
    classes: "Classes", 
    
    tasks: "Tasks", 
    task_name: "Task name", 
    score: "Score", 
    waiting: "Waiting", 
    actions: "Actions", 
    students: "Students", 


    // Profile
    profile_title: "My Profile",
    info_updated: "You have updated your info successfully.",
    update_your_profile: "Update your Profile",

    address:"Address",
    yourID:"Your ID",
    login_info:"Login Information",
    current_pass: "Current Password",
    new_pass:"New Password",
    confirm_pass:"Confirm Password",
    update_profile:"Update profile",
    

    // fav
    fav_title:"Favorites",
    fav_confirm_remove:"You are about to Remove a Question from Your favorite! are you sure?",
    questions:"Questions",

    questions_title:"Question Title",
    answers:"Answers",
    type:"type",
    remove:"Remove",
    sent_to_students:"Send to my students",


    // question types
    type_text:"Text",
    type_video:"Video",
    type_audio:"Audio",
    type_img:"Image",


    // Questions
    questions_new_task: "New Task",
    levels:"Levels",
    beginners:"Beginners",
    intermediate:"Intermediate",
    advanced:"Advanced",
    categories:"Categories",

    cant_get_data:"Can't get data from server.",
    questions_to_send:"Questions to send",

    select:"Select",
    question:"Question",


    // MyTasks
    my_tasks:"My Tasks",
    task_confirm_delete:"You are about to delete a Task! are you sure?",

    status:"Status",
    nothing_new:"Nothing new",
    cant_find_tasks:"We didn't find any Tasks in this section Yet.",

    
    // Tasks
    all_categories:"All categories",
    all_classes:"All classes",


    // TaskStart
    confirm_task_send: "You are about to send your answers to the teacher. Are you sure?",
    quiz_sent_success: "The quiz was submitted successfully",
    return_to_my_tasks:"Return to My Tasks",
    your_score_is:"Your score is",
    task_info:"Task Info",
    task_time:"Task time",

    starts:"Starts",
    ends:"Ends",
    unlimited:"Unlimited",
    timer:"Time allotted",
    time_allotted:"Time allotted",
    results:"Results",
    comments:"Comments",
    send_quiz_now:"Send Quiz Now",
    next:"Next",


    // StudentTasks
    number_of_waiting_tasks:"Tasks pending",
    my_grades_average:"My grades Average",
    finished_avg:"Final grade",
    not_started:"Not started",


    // Dictionary
    not_logged_in:"You are not logged in",
    dic_search_holder:"Search the dictionary",
    dictionary:"Dictionary",


    // MyTasksInfo
    about_update_status:"You are about to update the status! are you sure?",
    student_start_yet:"This student didn't start yet.",
    confirm_delete_answers:"You are about to delete all student answers! are you sure?",
    finished:"Finished",
    needs_attention:"Needs attention",
    didnt_start:"Didn't Start",
    minutes:"Minutes",
    automatically:"Automatically",
    teacher_decision:"Teacher decision",
    added:"Added",
    start_at:"Start at",
    ends_at:"Ends at",


    // Article
    msg_sent:"Your Message was sent successfully.",
    or_contact_form:"Or you can fill the form below, and we'll come back to you shortly.",
    send:"Send now",

    // games
    games:"Games",


    // Questions
    no_data_av: "There is No information on this topic."
};