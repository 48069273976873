import { useEffect, useState, useContext, useRef} from 'react'
import { Link, useLocation, useParams } from "react-router-dom";


import './css/Cp.css'
import { AppContext } from "../Context";
import Fetch, { Loader, dateToText } from "../inc/Fetch";

import Footer from "./Footer";
import Header from "./Header";
import SendTask from "./popups/SendTask";

import { RiQuestionAnswerLine } from "react-icons/ri";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { MdDeleteOutline, MdEdit, MdOutlineGroup } from "react-icons/md";


export default function StudentTasks() {

	const {success, userinfo, languageText} = useContext(AppContext);
	const [open, setopen] = success;
	const [logedIn, setlogedIn] = userinfo;
	const [text, settext] = languageText;

	const [loader, setloader] = useState(true);
	const [data, setdata] = useState([]);
	const [showsendTask, setshowsendTask] = useState(false); 

	let loc = useParams();

	useEffect(() => {
		

		Fetch("tasks/view", (d) => {

			if (d?.error) {
				setopen({
                    title:text?.alertErrorTitle,
                    short:d?.error,
                    error:true
                });
			} else {
				setdata(d);
			}

			setloader(false);
		});


	}, []);


	
	if (loader) {
		return (
			<Loader />
		);
	}


	return (
		<>
			<Header title={text?.my_tasks} />
			<div className='firstElem stats'>

				<div className='mainWidth'>


					<section>
						<div>
							<b>{text?.stats_task}</b>
							<h3>{data?.stats?.tasks}</h3>
							{/* <span className='up'><FaArrowUp /> 10.3%</span> */}
						</div>
					</section>

					<section>
						<div>
							<b>{text?.number_of_waiting_tasks}</b>
							<h3>{data?.stats?.notFinished}</h3>
							{/* <span className='up'><FaArrowUp /> 10.3%</span> */}
						</div>
					</section>

					<section>
						<div>
							<b>{text?.my_grades_average}</b>
							<h3>{data?.stats?.avg}%</h3>
							{/* <span><FaArrowDown /> 2.3%</span> */}
						</div>
					</section>


					


				</div>
			</div>


			<div className='body'>
				<div className='mainWidth '>

					<div className='cp'>



						<div className='containerBox' style={{flex:1, }}>
					
							<div className='quizzesList'>
								<table>
									<thead>
										<tr>
											<td style={{width:"60%"}} className='align-left'>{text?.task_name}</td>
											<td >{text?.questions}</td>
											<td>{text?.score}</td>
											<td>{text?.timer}</td>
											<td>{text?.status}</td>
										</tr>
									</thead>
									
									<tbody>
										{data?.tasks?.map((v, k) => {
											return (
												<tr className={!v?.st_score && !v?.st_finished && `checked`} key={k}>
													<td className='align-left'><Link to={`/TaskStart/${v?._id}`}><b>{v?.name}</b> {v?.full && <><br /><p>{v?.full}</p></>}</Link></td>
													<td>{v?.questions?.length}</td>
													<td>{v?.st_finished && v?.st_score ? <span className={`tag ${v?.st_score <= 50 ? "red" : "green"}`}>{v?.st_score}%</span> : "--"}</td>
										
													<td>
														{v?.st_timer ? <span className='tag'>{v?.st_timer}</span> : "--"}
													</td>
													<td>
														{v?.st_score && v?.st_finished ? <span className={`tag green`}>{text?.finished_avg}</span>: false}
														{v?.st_score && !v?.st_finished ? <span className='tag orange'>{text?.waiting}..</span>: false}
														{!v?.st_score && !v?.st_finished ? <span className='tag gray'>{text?.not_started}</span>: false}
													</td>
												
												</tr>
											);
										})}
										
									</tbody>
								</table>
							</div>
							
						</div>
					</div>


						
					
					
				</div>


			</div>

			<Footer />
		</>
	);
		
	
}
