import { useEffect, useState, useContext, useRef} from 'react'
import { Link, useLocation } from "react-router-dom";


import './css/Tasks.css'
import { AppContext } from "../Context";
import Fetch, { Loader } from '../inc/Fetch'
import { Tasks } from "../components/Elements";
import { Input, MultiSelect, ImageUpload } from "../components/Form";

import Footer from "./Footer";
import Header from "./Header";

const levels = [
    {
        name:"- All levels -",
        val:false,
    },
    {
        name:"Beginners",
        val:"Beginners",
    },
    {
        name:"Intermediate",
        val:"Intermediate",
    },
    {
        name:"Advanced",
        val:"Advanced",
    }
];


import { RiQuestionAnswerLine } from "react-icons/ri";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { GiShare } from "react-icons/gi";
import { IoIosAdd } from "react-icons/io";


export default function Training() {

	const active = useRef(true);
	const {success, userinfo, showQuiz} = useContext(AppContext);
	const [open, setopen] = success;
	const [user, setuser] = userinfo;

	const [showQuizD, setshowQuizD] = showQuiz;

	let splitCls = user?.userinfo?.classes[0]?.split("-")[0];

	const [pageLoad, setpageLoad] = useState(true); 
	const [data, setdata] = useState([]); 
	const [filter, setfilter] = useState({ classes: splitCls}); 
	const [cats, setcats] = useState([]); 
	const [cls, setcls] = useState([]); 
	

	

	useEffect(() => {

		
		if (active.current != filter) {
			active.current = filter;

			console.log(filter);

			setpageLoad(true);

			Fetch("Quiz/view", (d) => {

				if (!d) {
					setopen({
						title:"יש תקלה",
						short:"יש תקלה בשליפת נתונים אנא נסה שנית.",
						error:true
					});
				} else {

					setdata(d);

					let arrCats = [{ name: "- All Categories -", val:false}];
					d?.cats?.map((v) => {
						arrCats.push({ name: v?.name, val:v?._id});
					});
					setcats(arrCats);

					let arrClas = [{ name: "- All Classes -", val:false}];
					Object.keys(global.Classes)?.map((v) => {
						arrClas.push({ name: global.Classes[v], val:v});
					});
					setcls(arrClas);
				}

				setpageLoad(false);
			}, { filter:filter, training:true});
		
		}
	}, [filter]);

	

	return (
		
		<>
			
			<Header title="Training" />
			<div className='firstElem search'>
				<div className='mainWidth'>

					
				</div>
			</div>


			<section className='sect5 tasks'>

				{pageLoad ? <Loader /> :

				<div className='mainWidth' >
					{/* <hr className='boxLine'/>
					<h4 className='boxTitle'>Tasks List</h4>
					 */}
					<div className='sect5Box' style={{marginTop:0}}>
						{data?.tasks?.length ? data?.tasks?.map((v, k) => {
							return (
								<Tasks key={k} data={v} />
							);
						})
						
						:
							<div></div>
						}

					</div>
					
					
				</div>}
			</section>

			<Footer />
		</>
	)
}
