import { useEffect, useState, useContext, useRef} from 'react'
import { Link, useLocation } from "react-router-dom";

import manSrc from '../images/2884334.jpg'

import './css/quiz.css'
import { AppContext } from "../Context";


export default function Cp() {

	const {success, userinfo} = useContext(AppContext);
	const [open, setopen] = success;


	return (
		
		<>

            <div className='quizBody'>
                <div className='quizBox'>

                    <div className='quizHead'>
                        <img src={manSrc} />
                    </div>

                    <div className='quizBg'>
                        <div className='catBox'>
                            <span>Present simple</span>
                        </div>

                        <h1>The teachers always ______________ how important it is to do homework.</h1>
                    </div>

                    
                    
                </div>
            </div>

		</>
	)
}
