import { useEffect, useState, useContext, useRef} from 'react'
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";

import { AppContext } from "../Context";
import login from "../images/6333038.png";
import './css/error.css'

export default function MyTasks() {
	
	const {success, userinfo} = useContext(AppContext);
    const [logedIn, setlogedIn] = userinfo;


	let location = useLocation();
	let pages = [];
	let type = 0;

	// if (!logedIn?.userinfo?._id) {

	// 	pages = ["Cp", "Profile", "Fav", "Tasks", "MyTasks", "Students"];

	// } else {

	// }
	// 

	if (!type) {
		return (
			<>
				<div className='errorMainBox'>
					<div className='errorBox'>
						<img src={login} />
						<h1>Opss!!</h1>
						<h2>No data available</h2>
						<p>Please login to your account and try again.</p>

						<Link to="/" className='btn'>Return to Home page</Link>
						
					</div>
				</div>
			</>
		);
	} else {
		return (
			<>
				<div className='errorMainBox'>
					<div className='errorBox'>
						<img src={login} />
						<h1>Opss!!</h1>
						<h2>You do not have permission to view this content.</h2>
						<p>Please login to your account and try again.</p>

						<Link to="/" className='btn'>Return to Home page</Link>
						
					</div>
				</div>
			</>
		);
	}
		
	
}
